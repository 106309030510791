export default class RoutePaths {
    static about = "/about";
    static home = "/";
    static salons = "/#salons";
    static login = "/login";
    static imprint = "/impressum";
    static privacyNotice = "/datenschutz";
    static adminAbout = "/admin/about";
    static adminImprint = "/admin/imprint";
    static adminPrivacyNotice = "/admin/privacyNotice";
    static adminNewsList = "/admin/news/list";
    static adminNewsEdit = "/admin/news/:id/edit";
    static adminNewsCreate = "/admin/news/create";
    static adminGalleryList = "/admin/gallery/list";
    static adminGalleryEdit = "/admin/gallery/:id/edit";
    static adminGalleryCreate = "/admin/gallery/create";
    static adminEmployeeList = "/admin/employee/list";
    static adminEmployeeCreate = "/admin/employee/create";
    static adminEmployeeEdit = "/admin/employee/:id/edit";
    static adminProductList = "/admin/product/list";
    static adminProductCreate = "/admin/product/create";
    static adminProductEdit = "/admin/product/:id/edit";
    static adminProductCategoryList = "/admin/productCategory/list";
    static adminProductCategoryCreate = "/admin/productCategory/create";
    static adminProductCategoryEdit = "/admin/productCategory/:id/edit";
    static adminStoreList = "/admin/store/list";
    static adminStoreCreate = "/admin/store/create";
    static adminStoreEdit = "/admin/store/:id/edit";
    static adminPush = "/admin/push";
    static adminHotNewsList = "/admin/hotNews/list";
    static adminHotNewsEdit = "/admin/hotNews/:id/edit";
    static adminHotNewsCreate = "/admin/hotNews/create";
    static adminPriceListCreate = "/admin/priceList/create";
    static storeDetail = "/storeDetail/:id";
    static products = "/products";
    static contact = "/contact";
    static news = "/news";
    static newsDetail = "/news/:id";
}
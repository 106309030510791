import * as React from "react";
import FirebasePaths from "../../../../Repository/FirebasePaths";
import "./newsOverview.css"
import MultiContentRepository from "../../../../Repository/MultiContentRepository";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import {withRouter} from "react-router-dom";
import RoutePaths from "../../../../Config/RoutePaths";

class NewsOverview extends React.Component {


    state = {
        news: [],
        hotNews: [],
    };

    constructor(props) {
        super(props);
        this.repositoryNews = new MultiContentRepository(FirebasePaths.News);
        this.repositoryHotNews = new MultiContentRepository(FirebasePaths.HotNews)
    }


    componentDidMount() {

        this.repositoryHotNews
            .getContentLimitAndOrderByCreationDate(3)
            .then(doc => {
                let hotNews = [];
                doc.forEach(element => {
                    hotNews.push({
                        id: element.id,
                        data: element.data()
                    });
                    this.setState({hotNews: hotNews});
                });
            });

        this.repositoryNews
            .getContentLimitAndOrderByCreationDate(9)
            .then(doc => {
                let news = [];
                doc.forEach(element => {
                    news.push({
                        id: element.id,
                        data: element.data()
                    });
                    this.setState({news: news});
                });
            })

    }

    openNews = (id, type) => {
        this.props.history.push(RoutePaths.newsDetail.replace(":id", id) + "?type=" + type);
    };

    render() {
        return (
            <div>
                <Header text="News"
                        image="https://firebasestorage.googleapis.com/v0/b/international-hair-group-app.appspot.com/o/mood%2Fnews.jpg?alt=media&token=585fbbfe-753b-4be2-a033-729801ffe12b"
                        blendMode={true}/>

                {this.state.hotNews && this.state.hotNews.length > 0 &&
                <div className="newsBoxPadding">
                    <div className="newsTitle">Aktionen</div>
                    <div className="hotNewsContainer">
                        {this.state.hotNews.map((item, index) => (
                            <div className="hotNews" key={index}
                                 onClick={() => this.openNews(item.id, FirebasePaths.HotNews)}>
                                <img alt="" className="hotNewsImage" src={item.data.images[0].url}/>
                                <div className="hotNewsText">
                                    <div className="hotNewsTitle">{item.data.title}</div>
                                    <div className="hotNewsBody">{item.data.body}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {this.state.news && this.state.news.length > 0 &&
                <div className="newsBoxPadding">
                    <div className="newsTitle">News</div>
                    <div className="hotNewsContainer">
                        {this.state.news.map((item, index) => (
                            <div className="news" key={index}
                                 onClick={() => this.openNews(item.id, FirebasePaths.News)}>
                                <img alt="" className="newsImage" src={item.data.images[0].url}/>
                                <div className="newsText">
                                    <div className="hotNewsTitle">{item.data.title}</div>
                                    <div className="hotNewsBody">{item.data.body}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                <Footer/>
            </div>
        );
    }

}

export default withRouter(NewsOverview)
import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import ListViewComponent from "../../Util/ListViewComponent";
import RoutePaths from "../../../Config/RoutePaths";

export default class NewsListAdmin extends React.Component {

    render() {
        return (
            <ListViewComponent
                max={8}
                createPath={RoutePaths.adminNewsCreate}
                editPath={RoutePaths.adminNewsEdit}
                createButtonLabel="Neue News"
                firebasePath={FirebasePaths.News}/>
        );
    }
}

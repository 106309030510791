import React from "react";

export default class DefaultInputHandlingComponent extends React.Component {
    /**
     * @return {undefined}
     */
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event) => {
        let updatedState = {...this.state};
        updatedState[event.target.name] = event.target.value;
        this.setState(updatedState);
    };

    imageAdded = (image) => {
        this.state.images.push(image);
        this.setState({images: this.state.images});
    };
    imageDelete = (item) => {
        this.state.images.splice(this.state.images.indexOf(item), 1);
        this.setState({images: this.state.images});
    };

    hideLoading() {
        this.setState({loading: false});
    }


    showLoading() {
        this.setState({loading: true});
    }

    handleChangeCheckbox = (event) => {
        let updatedState = {...this.state};
        updatedState[event.target.name] = event.target.checked;
        this.setState(updatedState);
    };


    handleSubmit(redirect) {

        let allGood;
        let data = this.getContent();

        for (let key in data) {
            if(key === 'sendPush'){
                continue;
            }
            let value = data[key];
            if (isNaN(value)) {
                allGood = value && value.length > 0;
            } else if (!isNaN(value)) {
                allGood = value && value > 0;
            } else if (value && value.constructor === Array && value.size > 0) {
                allGood = true;
            }
            // allGood = (data[key] && data[key].length > 0) || (data[key].constructor === Array && data[key].size > 0)
            if (!allGood) {
                break;
            }
        }

        if (allGood) {
            if (this.id) {
                this.updateEntity(redirect);
            } else {
                this.createEntity(redirect);
            }
        } else {
            window.alert("Bitte alle Felder befüllen")
        }
    }

    updateEntity(redirect) {
        this.repository
            .updateContent(this.id, this.getContent())
            .then(() => {
                if (redirect) {
                    this.successful();
                }
            })
            .catch(err => {
                this.error();
            });
    }

    error() {
        window.alert("Fehler beim speichern")
    }

    successful() {
        this.props.history.push(this.redirectPath);
        window.alert("Erfolgreich gespeichert")
    }

    createEntity(redirect) {
        this.repository
            .saveContent(this.getContent())
            .then(() => {
                if (redirect) {
                    this.successful();
                }
            })
            .catch(err => {
                this.error();
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.state)
    }

}

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

class FirebaseConfig {
    // static firebaseApp = firebase.initializeApp({
    //     apiKey: "AIzaSyAJdL62XFLCUqQoV5hDVSWIi4yXDdeiu4k",
    //     authDomain: "zaferdev-9c062.firebaseapp.com",
    //     databaseURL: "https://zaferdev-9c062.firebaseio.com",
    //     projectId: "zaferdev-9c062",
    //     storageBucket: "zaferdev-9c062.appspot.com",
    //     messagingSenderId: "389492884169"
    // });

    static firebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyABkhhvV7uqZPCmRxGhnHZ2CJdJm4komwM",
        authDomain: "international-hair-group-app.firebaseapp.com",
        databaseURL: "https://international-hair-group-app.firebaseio.com",
        projectId: "international-hair-group-app",
        storageBucket: "international-hair-group-app.appspot.com",
        messagingSenderId: "424506070996"
    });

    static firestore() {
        const firestoreInstance = this.firebaseApp.firestore();
        firestoreInstance.settings({
            timestampsInSnapshots: true
        });
        return firestoreInstance;
    }

    static auth() {
        return this.firebaseApp.auth();
    }

    static storage() {
        return this.firebaseApp.storage();
    }
}

export default FirebaseConfig;

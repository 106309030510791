import React from "react"
import Button from '@material-ui/core/Button';
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import "../Util/adminPannel.css"
import Loading from "../../Util/Loading";
import SingleContentRepository from "../../../Repository/SingleContentRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";


export default class PushAdminComponent extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            title: "",
            body: "",
            url: "",
            privateKey: ""
        };

        this.repository = new SingleContentRepository(FirebasePaths.Config);


        this.repository
            .getContent()
            .then(doc => {

                this.setState({
                    loading: false,
                    url: doc.data().pushUrl,
                    privateKey: doc.data().privateKey
                })

            })
            .catch(err => {
                console.log("Error getting documents", err);
            });
    }

    handleSubmit() {


        let data = {
            title: this.state.title,
            body: this.state.body,
        };


        let allGood;

        for (let key in data) {
            allGood = (data[key] && data[key].length > 0) || (data[key].constructor === Array && data[key].size > 0)
            if (!allGood) {
                break;
            }
        }

        if (allGood) {
            this.showLoading();

            fetch(this.state.url, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Headers": "private_key",
                    "private_key": this.state.privateKey
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    console.log(response.status);
                    alert("Push wurde versandt");
                    this.hideLoading()
                })
                .catch(error => {
                    console.log({error});
                    alert("Fehler beim senden");
                    this.hideLoading()
                });

        } else {
            window.alert("Bitte alle Felder befüllen")
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }
        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <h1>Push</h1>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Überschrift"
                                    name="title"
                                    type="text"
                                    required={true}
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    id="Admin-Description"
                                    fullWidth
                                    label="Beschreibung"
                                    name="body"
                                    required={true}
                                    multiline={true}
                                    rows={15}
                                    value={this.state.body}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <Button variant="contained" color="primary" onClick={() => this.handleSubmit(true)}>
                                    Senden
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

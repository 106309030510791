import * as React from "react";
import SingleContentRepository from "../../../Repository/SingleContentRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";
import "./priceList.css"

export default class PriceList extends React.Component {


    state = {
        title: "",
        body: "",
        url: ""

    };

    constructor(props) {
        super(props);
        this.repository = new SingleContentRepository(FirebasePaths.PriceList)
    }

    componentDidMount() {
        this.repository
            .getContent()
            .then(doc => {
                this.setState(doc.data())
            })
    }


    render() {
        return (
            <div className="priceList">
                <div className="priceListTitle">Preisliste</div>
                <div className="priceListBody">Hier können Sie sich die aktuelle Preisliste unserer Salons
                    anschauen!
                </div>
                <a  rel="noopener noreferrer" target="_blank" href={this.state.url} className="priceListButton">Preisliste anschauen</a>
            </div>
        );
    }

}
import React from "react";
import {Link, withRouter} from "react-router-dom";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import RoutePaths from "../../../Config/RoutePaths";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";

import "./navigation.css"

class Navigation extends React.Component {
    state = {
        anchorEl: null,
        signedIn: false
    };

    constructor(props) {
        super(props);
        const _self = this;
        this.repository = new MultiContentRepository(FirebasePaths.Admin);
        FirebaseAuthManager.auth().onAuthStateChanged(user => {
            if (user && user.uid) {
                this.repository
                    .getContentById(user.uid)
                    .then(doc => {
                        if (doc && doc.exists) {
                            _self.setState({
                                signedIn: true
                            })
                        } else {
                            this.signOut();
                        }
                    });
            } else {
                this.signOut();
            }

        });
    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    signOut() {
        this.setState({
            signedIn: false
        });
        FirebaseAuthManager.signOut();
        // this.props.history.push(RoutePaths.login);
    }

    render() {


        return (
            <div className="navigation">
                <Link to={RoutePaths.home}>
                    <img className="navigationLogo" src={require("./logo.svg")} alt=""/>
                </Link>
                <Toolbar className="navigationBar" id="navigationBar">
                    {(!this.state.signedIn &&
                        <div>
                            <Link
                                className={this.props.location.pathname === RoutePaths.home ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.home}>Home</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.products ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.products}>Produkte</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.news ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.news}>News</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.contact ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.contact}>Kontakt</Link>
                        </div>)}
                    {(this.state.signedIn &&
                        <div>

                            <Link
                                className={this.props.location.pathname === RoutePaths.adminAbout ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminAbout}>Über uns</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminImprint ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminImprint}>Impressum</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminPrivacyNotice ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminPrivacyNotice}>Datenschutzerklärung</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminNewsList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminNewsList}>
                                News</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminHotNewsList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminHotNewsList}>
                                Aktionen</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminGalleryList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminGalleryList}>
                                Gallery</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminEmployeeList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminEmployeeList}>
                                Angestellte</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminProductList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminProductList}>
                                Produkte</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminProductCategoryList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminProductCategoryList}>
                                Produktkategorie</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminStoreList ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminStoreList}>
                                Geschäfte</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminPriceListCreate ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminPriceListCreate}>
                                Preisliste</Link>
                            <Link
                                className={this.props.location.pathname === RoutePaths.adminPush ? 'navigationButton active' : 'navigationButton'}
                                to={RoutePaths.adminPush}>
                                PUSH</Link>
                            <Link className="navigationButton" to="" onClick={() => this.signOut()}>Logout</Link>
                        </div>)}
                </Toolbar>
            </div>
        );
    }
}

export default withRouter(Navigation)


import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import ListViewComponent from "../../Util/ListViewComponent";
import RoutePaths from "../../../Config/RoutePaths";

export default class ProductCategoryListAdmin extends React.Component {

    render() {
        return (<ListViewComponent
            createPath={RoutePaths.adminProductCategoryCreate}
            editPath={RoutePaths.adminProductCategoryEdit}
            createButtonLabel="Neues Produktkategorie "
            firebasePath={FirebasePaths.ProductCategory}/>);
    }
}

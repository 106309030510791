import * as React from "react";
import "./openingHours.css"

export default class OpeningHours extends React.Component {


    state = {
        openingHours: ""
    };

    componentDidMount() {
        this.extracted(this.props);
    }

    extracted(props) {

        let array = [props.openingHours.monday.trim(),
            props.openingHours.tuesday.trim(),
            props.openingHours.wednesday.trim(),
            props.openingHours.thursday.trim(),
            props.openingHours.friday.trim()];

        if (array.every((v) => {
            return v === array[0];
        })) {
            this.setState({
                openingHours: <div>
                    <div>Montag - Freitag: {props.openingHours.monday}</div>
                    <div>Samstag: {props.openingHours.saturday}</div>
                    <div>Sonntag: {props.openingHours.sunday}</div>
                </div>
            })
        } else {
            this.setState({
                openingHours:
                    <div>
                        <div>Montag: {props.openingHours.monday}</div>
                        <div>Dienstag: {props.openingHours.tuesday}</div>
                        <div>Mittwoch: {props.openingHours.wednesday}</div>
                        <div>Donnerstag: {props.openingHours.thursday}</div>
                        <div>Freitag: {
                        props.openingHours.friday}</div>
                        <div>Samstag: {props.openingHours.saturday}</div>
                        <div>Sonntag: {props.openingHours.sunday}</div>
                    </div>
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.extracted(nextProps);
    }

    render() {
        return (
            <div className="openingHours">
                <div>
                    <div className="openingHoursTitle">Öffnungszeiten</div>
                    <div className="openingHoursBody">{this.state.openingHours}</div>
                </div>
            </div>);
    }

}
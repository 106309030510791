import React from "react";
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import {FirebaseStorageRepository} from "../../../Repository/FirebaseStorageRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";
import SingleContentRepository from "../../../Repository/SingleContentRepository";

export default class PriceListAdmin extends DefaultInputHandlingComponent {

    state = {
        url: "",
        path: ""
    };

    constructor(props) {
        super(props);
        this.firebaseStorageRepository = new FirebaseStorageRepository(FirebasePaths.PriceList);
        this.firebasePriceListRepository = new SingleContentRepository(FirebasePaths.PriceList);
    }

    componentDidMount() {
        this.firebasePriceListRepository.getContent().then(content => {
            this.setState(content.data())
        })
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        this.firebaseStorageRepository
            .store(file, ".pdf")
            .then(p => {
                p.ref.getDownloadURL()
                    .then(url => {
                        this.setState({url: url, path: p.ref.location.path});
                        this.firebasePriceListRepository.saveContent({
                            url: url,
                            path: p.ref.location.path
                        }).then(() => window.alert("Alban ist ein kleiner Analprinz"))
                    });
            });
    };

    render() {
        return (
            <Grid container justify="center" className="Padding-content, adminComponentBody">
                <Grid item xs={8}>
                    <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                        <h1>Preisliste</h1>
                        <div className="adminComponentTopMargin">
                            Hinterlegte Preisliste: <a  rel="noopener noreferrer" target="_blank" href={this.state.url}>{this.state.path}</a>
                        </div>
                        <div className="adminComponentTopMargin">
                            <input accept="application/pdf" type="file" onChange={this.fileChangedHandler}/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

import FirebaseConfig from "../Config/FirebaseConfig";

export default class MultiContentRepository {
    constructor(fireStorePath) {
        this.fireStorePath = fireStorePath;
    }


    getContent() {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .get();
    }

    getContentLimit(limit) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .limit(limit)
            .get();
    }

    getContentLimitAndOrderByCreationDate(limit) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .limit(limit)
            .orderBy("creationDate", "desc")
            .get();
    }

    getContentWhere(field, operater, value) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .where(field, operater, value)
            .orderBy("creationDate", "desc")
            .get();
    }


    saveContent(content) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .add(content);
    }

    deleteContent(contentId) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .doc(contentId)
            .delete();
    }

    getContentById(contentId) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .doc(contentId)
            .get();
    }

    updateContent(id, data) {
        return FirebaseConfig.firestore()
            .collection(this.fireStorePath)
            .doc(id)
            .update(data)
    }
}

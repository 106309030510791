import React from "react"
import Button from '@material-ui/core/Button';

import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import Loading from "../../Util/Loading";
import FirebasePaths from "../../../Repository/FirebasePaths";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import RoutePaths from "../../../Config/RoutePaths";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

export default class ProductCategoryAdmin extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);
        this.redirectPath = RoutePaths.adminProductCategoryList;
        this.id = props.match.params.id;
        this.state = {
            loading: (this.id),
            title: "",
        };
        this.repository = new MultiContentRepository(FirebasePaths.ProductCategory);
        if (this.id) {
            this.repository
                .getContentById(this.id)
                .then(doc => {
                    let data = doc.data();
                    this.setState(data);
                    this.setState({
                        loading: false
                    })
                })
                .catch(err => {
                    window.alert("Fehler beim laden der Daten")
                });
        }

    }

    getContent() {
        return {
            title: this.state.title
        };
    }

    render() {

        if (this.state.loading) {
            return <Loading/>
        }

        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }


        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <h1>ProduktKategorie</h1>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    fullWidth
                                    label="Überschrift"
                                    name="title"
                                    type="text"
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>

                            <div className="adminComponentTopMargin">
                                <Button fullWidth variant="contained" color="primary"
                                        onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }


}

import * as React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import Boring from "../BoringScreen/Boring";
import Footer from "../Footer/Footer";

export default class PrivacyNotice extends React.Component {
    render() {
        return (<div>
            <Boring repo={FirebasePaths.PrivacyNotice}/>
            <Footer/>
        </div>);
    }
}
import React from "react";
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import FirebasePaths from "../../../Repository/FirebasePaths";
import SingleContentAdminComponent from "../../Util/SingleContentAdminComponent";

export default class AboutUsAdmin extends DefaultInputHandlingComponent {
    render() {
        return (
            <SingleContentAdminComponent
                pageTitle="Über uns"
                firebasePath={FirebasePaths.AboutUs}
            />
        );
    }
}

import React from "react";
import FirebaseConfig from "../../../Config/FirebaseConfig";
import Button from "@material-ui/core/Button/Button";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import TextField from "@material-ui/core/TextField/TextField";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import Grid from "@material-ui/core/Grid/Grid";
import "../../Admin/Util/adminPannel.css"
import Paper from "@material-ui/core/Paper/Paper";
import RoutePaths from "../../../Config/RoutePaths";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

export default class Home extends React.Component {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        FirebaseConfig.auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(authUser => {
                this.props.history.push(RoutePaths.home)
            })
            .catch(error => {
                console.log(error);
            });

        event.preventDefault();
    }

    render() {
        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={4}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <h1>Login</h1>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup className="Margin-bottom, adminComponentTopMargin">
                                    <FormLabel>Email</FormLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        value={this.state.email}
                                        placeholder={"Hier eingeben...."}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>

                                <FormGroup className="Margin-bottom, adminComponentTopMargin">
                                    <FormLabel>Password</FormLabel>
                                    <TextField
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        placeholder={"Hier eingeben...."}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                                <div className="adminComponentTopMargin">
                                    <Button variant="contained" color="primary" type="submit">Submit</Button>
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

import * as React from "react";
import "./newsDetail.css"
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import {withRouter} from "react-router-dom";
import MultiContentRepository from "../../../../Repository/MultiContentRepository";

class NewsDetail extends React.Component {


    state = {
        title: "",
        body: "",
        image: ""
    };

    constructor(props) {
        super(props);
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const type = params.get('type');
        this.repository = new MultiContentRepository(type)
    }

    componentDidMount() {
        this.repository
            .getContentById(this.props.location.pathname.replace("/news/", ""))
            .then(doc => {
                this.setState({
                    title: doc.data().title,
                    body: doc.data().body,
                    image: doc.data().images[0].url
                })
            })
    }

    render() {
        return (
            <div>
                <Header text=""
                        image={this.state.image}
                        blendMode={false}/>
                <div className="newsDetail">
                    <div className="newsDetailTitle">{this.state.title}</div>
                    <div className="newsDetailBody">{this.state.body}</div>
                </div>
                <Footer/>
            </div>);
    }

}

export default withRouter(NewsDetail)
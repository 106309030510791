import React from "react";
import "./storeOverview.css"
import MultiContentRepository from "../../../../Repository/MultiContentRepository";
import FirebasePaths from "../../../../Repository/FirebasePaths";
import RoutePaths from "../../../../Config/RoutePaths";
import {withRouter} from "react-router-dom";

class StoreOverview extends React.Component {

    state = {
        stores: [],
    };

    constructor(props) {
        super(props);
        this.repository = new MultiContentRepository(FirebasePaths.Store);

    }

    componentDidMount() {
        this.repository
            .getContent()
            .then(collection => {
                const tempItems = [];
                collection.forEach(element => {
                    tempItems.push({
                        id: element.id,
                        data: element.data()
                    })
                });
                console.log(tempItems);
                this.setState({
                    stores: tempItems
                });
            })
    }

    navigateTo = (id) => {
        this.props.history.push(RoutePaths.storeDetail.replace(":id", id));
    };

    render() {
        return (
            <div id="salons" className="storeOverview">
                <div className="storeOverviewTitle">Salons</div>
                {this.state.stores && this.state.stores.length > 0 &&
                <div className="table">
                    {this.state.stores.map((item, index) => (
                        <div className="item" key={index} onClick={() => this.navigateTo(item.id)}>
                            <img className="itemImage" src={item.data.images[0].url} alt=""></img>
                            <div className="storeTitle">{item.data.title}</div>
                            <div className="toStoreButton">Zum Salon</div>

                        </div>
                    ))}
                </div>}
            </div>);
    }


}

export default withRouter(StoreOverview)
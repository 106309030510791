import * as React from "react";
import SingleContentRepository from "../../../Repository/SingleContentRepository";
import "./boring.css"
import DOMPurify from "dompurify"

export default class Boring extends React.Component {


    state = {
        title: "",
        body: "",
    };

    constructor(props) {
        super(props);
        this.repository = new SingleContentRepository(this.props.repo)
    }


    componentDidMount() {
        this.repository
            .getContent()
            .then(doc => {
                this.setState(doc.data())
            })
    }

    render() {
        return (
            <div className="boring">
                <div>
                    <div className="boringTitle">{this.state.title}</div>
                    <div
                        className="boringBody"
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.body)}}></div>
                </div>
            </div>);
    }

}
import React from "react"
import Button from '@material-ui/core/Button';

import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import Loading from "../../Util/Loading";
import "../Util/adminPannel.css"
import FirebasePaths from "../../../Repository/FirebasePaths";
import Paper from "@material-ui/core/Paper/Paper";
import {DatePicker} from "material-ui-pickers";
import Grid from "@material-ui/core/Grid/Grid";
import Moment from "moment";
import {ImageUploadGrid} from "../../Util/ImageUploadGrid";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {withRouter} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

class BaseNewsAdmin extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);


        this.id = props.match.params.id;
        this.momentStart = new Moment().utc().startOf('day');
        this.momentEnd = new Moment().utc().endOf('day');
        this.state = {
            loading: (this.id),
            title: "",
            body: "",
            published: false,
            sendPush:false,
            images: [],
            startDate: this.momentStart,
            endDate: this.momentEnd
        };

        this.repository = new MultiContentRepository(this.props.firebasePath);

        if (this.id) {
            this.repository
                .getContentById(this.id)
                .then(doc => {
                    let data = doc.data();
                    data.startDate = Moment.unix(data.startDate).utc(false);
                    data.endDate = Moment.unix(data.endDate).utc(false);
                    this.setState(data);
                    this.setState({
                        loading: false
                    })
                })
                .catch(err => {
                    window.alert("Fehler beim laden der Daten")
                });
        }

    }

    getContent() {

        if (this.props.firebasePath === FirebasePaths.HotNews) {
            return {
                title: this.state.title,
                body: this.state.body,
                images: this.state.images,
                sendPush: this.state.sendPush,
                startDate: this.state.startDate.unix(),
                endDate: this.state.endDate.unix()

            };
        } else {
            return {
                title: this.state.title,
                body: this.state.body,
                sendPush: this.state.sendPush,
                images: this.state.images,
            };
        }


    }

    handleDateStart = (date) => {
        this.setState({startDate: date.utc().startOf('day')});
    };
    handleDateEnd = (date) => {
        this.setState({endDate: date.utc().endOf('day')});
    };

    render() {

        if (this.state.loading) {
            return <Loading/>
        }

        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }


        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>

                            <h1>{this.props.title}</h1>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Überschrift"
                                    name="title"
                                    type="text"
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>

                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Beschreibung"
                                    name="body"
                                    multiline={true}
                                    rows={15}
                                    value={this.state.body}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            {this.props.firebasePath === FirebasePaths.HotNews && (
                                <div>
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        label="Beginn"
                                        value={this.state.startDate}
                                        date={this.state.startDate}
                                        onChange={this.handleDateStart}
                                        animateYearScrolling/>
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        label="Ende"
                                        value={this.state.endDate}
                                        date={this.state.endDate}
                                        onChange={this.handleDateEnd}
                                        animateYearScrolling/>
                                    <div>
                                        <Checkbox
                                            value="published"
                                            checked={this.state.published}
                                            onChange={this.handleChangeCheckbox}
                                            name="published"
                                        />
                                        Veröffentlicht
                                    </div>
                                </div>
                            )}
                            <div>
                                <Checkbox
                                    value="sendPush"
                                    checked={this.state.sendPush}
                                    onChange={this.handleChangeCheckbox}
                                    name="sendPush"
                                />
                                Push versenden
                            </div>
                            <div className="adminComponentTopMargin">
                                <ImageUploadGrid
                                    name="images"
                                    imagesMax={1}
                                    showLoading={() => this.showLoading()}
                                    hideLoading={() => this.hideLoading()}
                                    images={this.state.images}
                                    imageDelete={this.imageDelete}
                                    imageAdded={this.imageAdded}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <Button fullWidth variant="contained" color="primary"
                                        onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }


}

export default withRouter(BaseNewsAdmin)
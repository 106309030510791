import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import ListViewComponent from "../../Util/ListViewComponent";
import RoutePaths from "../../../Config/RoutePaths";

export default class StoreListAdmin extends React.Component {

    render() {
        return (<ListViewComponent
            createPath={RoutePaths.adminStoreCreate}
            editPath={RoutePaths.adminStoreEdit}
            createButtonLabel="Neues Geschäft "
            firebasePath={FirebasePaths.Store}/>);
    }
}

import React from 'react';
import "./header.css"

export default class Header extends React.Component {

    render() {
        return (
            <div className={(this.props.blendMode === true) ? "headerBlendMode" : "header"}
                 style={{backgroundImage: `url(${this.props.image})`}}>
                <div className="headeOverlay">
                    <div className="headerContent">{this.props.text}</div>
                </div>
            </div>);
    }
}
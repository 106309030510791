import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import RoutePaths from "../../../Config/RoutePaths";
import ListViewComponent from "../../Util/ListViewComponent";

export default class GalleryListAdmin extends React.Component {

    render() {
        return (<ListViewComponent
            createPath={RoutePaths.adminGalleryCreate}
            editPath={RoutePaths.adminGalleryEdit}
            createButtonLabel="Neue Gallery "
            firebasePath={FirebasePaths.Gallery}/>);
    }
}

import FirebaseConfig from "../Config/FirebaseConfig";
import uuidv4 from 'uuid/v4';

export class FirebaseStorageRepository {


    constructor(firebaseStoragePath) {
        this.firebaseStoragePath = firebaseStoragePath;
    }

    store(data, fileEnding) {
        return FirebaseConfig
            .storage()
            .ref(this.firebaseStoragePath + uuidv4() + fileEnding)
            .put(data);
    }

    delete(filename) {
        return FirebaseConfig
            .storage()
            .ref(filename)
            .delete();
    }
}
import * as React from "react";
import MultiContentRepository from "../../Repository/MultiContentRepository";
import Loading from "./Loading";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Link from "react-router-dom/es/Link";
import "../Admin/Util/adminPannel.css"
import Button from "@material-ui/core/Button/Button";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {withRouter} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

class ListViewComponent extends React.Component {
    state = {
        items: [],
        loading: true
    };

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);
        this.repository = new MultiContentRepository(this.props.firebasePath);
        this.loadData();
    }

    loadData() {
        this.repository.getContent("").then(result => {
            const tempItems = [];
            result.forEach(element => {
                tempItems.push({
                    id: element.id,
                    data: element.data()
                });
            });
            this.setState({
                items: tempItems,
                loading: false
            });
        });
    }


    itemDelete(item) {
        this.setState({
            loading: true
        });
        this.repository
            .deleteContent(item)
            .then(() => {
                this.loadData()
            }).catch(() => {
            window.alert("Fehler beim löschen")
        })
    }


    render() {
        if (this.state.loading) {
            return <Loading/>
        }

        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <Link to={this.props.createPath} className="Padding-content">
                                {((this.props.max && this.state.items.length < this.props.max) || typeof this.props.max === "undefined") &&
                                < Button variant="contained" color="primary">
                                    {this.props.createButtonLabel}
                                </Button>}
                            </Link>
                            <div className="adminComponentTopMargin">
                                <List>
                                    {this.state.items.map((item) => (
                                        <ListItem key={item.id} button onClick={() => this.navigateTo(item.id)}>
                                            <Avatar size={150}
                                                    src={(((item.data.images) && item.data.images.length > 0) ? item.data.images[0].url : "")}/>
                                            <ListItemText>{item.data.title ? item.data.title : item.data.name}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="Delete"
                                                            onClick={() => this.itemDelete(item.id)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>

        );
    }

    navigateTo = (id) => {
        this.props.history.push(this.props.editPath.replace(":id", id));
    }
}

export default withRouter(ListViewComponent)
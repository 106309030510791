import React from "react";
import "../Header/header.css"
import Header from "../Header/Header";
import AboutUs from "../AboutUs/AboutUs";
import Gallery from "../Gallery/Gallery";
import Footer from "../Footer/Footer";
import PriceList from "../PriceList/PriceList";
import StoreOverview from "../Store/Overview/StoreOverview";

export default class Home extends React.Component {

    render() {
        return (
            <div>
                <Header
                    blendMode={false}
                    text=""
                    image="https://firebasestorage.googleapis.com/v0/b/international-hair-group-app.appspot.com/o/mood%2Findex.jpg?alt=media&token=59a8992b-c805-4f8e-a507-f016b070d955"/>
                <AboutUs/>
                <StoreOverview/>
                <PriceList/>
                <Gallery/>
                <Footer/>
            </div>
        );
    }
}

import React from "react"
import Button from '@material-ui/core/Button';

import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import Loading from "../../Util/Loading";
import "../Util/adminPannel.css"
import FirebasePaths from "../../../Repository/FirebasePaths";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import RoutePaths from "../../../Config/RoutePaths";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import {ImageUploadGrid} from "../../Util/ImageUploadGrid";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

export default class ProductAdmin extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);


        this.redirectPath = RoutePaths.adminProductList;
        this.id = props.match.params.id;
        this.state = {
            loading: (this.id),
            categories: [],
            category: "",
            title: "",
            body: "",
            price: "",
            images: [],
            sendPush: false
        }
        ;

        this.repository = new MultiContentRepository(FirebasePaths.Product);
        this.categoryRepository = new MultiContentRepository(FirebasePaths.ProductCategory);
        this.categoryRepository.getContent("").then(result => {
            const tempItems = [];
            result.forEach(element => {
                tempItems.push({
                    id: element.id,
                    title: element.data().title
                });
            });
            this.setState({
                categories: tempItems,
                loading: false
            });
        });

        if (this.id) {
            this.repository
                .getContentById(this.id)
                .then(doc => {
                    this.setState(doc.data());
                    this.setState({
                        loading: false
                    })
                })
                .catch(err => {
                    window.alert("Fehler beim laden der Daten")
                });
        }

    }

    getContent() {
        return {
            title: this.state.title,
            body: this.state.body,
            category: this.state.category,
            price: this.state.price,
            images: this.state.images,
            sendPush: this.state.sendPush
        };
    }

    render() {

        if (this.state.loading) {
            return <Loading/>
        }

        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }


        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>

                            <h1>Produkt</h1>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Überschrift"
                                    name="title"
                                    type="text"
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Beschreibung"
                                    name="body"
                                    multiline={true}
                                    rows={15}
                                    value={this.state.body}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Preis"
                                    name="price"
                                    value={this.state.price}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <InputLabel>Produktkategorie</InputLabel>
                                <Select
                                    fullWidth
                                    id="category"
                                    value={this.state.category}
                                    onChange={this.handleInputChange}
                                    inputProps={{
                                        name: 'category',
                                        id: 'category',
                                    }}>
                                    <MenuItem value="">
                                        <em>Keine</em>
                                    </MenuItem>
                                    {
                                        this.state.categories.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.title}
                                            </MenuItem>))
                                    }
                                </Select>
                            </div>
                            <div>
                                <Checkbox
                                    value="sendPush"
                                    checked={this.state.sendPush}
                                    onChange={this.handleChangeCheckbox}
                                    name="sendPush"
                                />
                                Push versenden
                            </div>
                            <div className="adminComponentTopMargin">
                                <ImageUploadGrid
                                    name="images"
                                    imagesMax={1}
                                    showLoading={() => this.showLoading()}
                                    hideLoading={() => this.hideLoading()}
                                    images={this.state.images}
                                    imageDelete={this.imageDelete}
                                    imageAdded={this.imageAdded}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <Button fullWidth variant="contained" color="primary"
                                        onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>

        );
    }


}

import * as React from "react";
import SingleContentRepository from "../../../Repository/SingleContentRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";
import "./aboutUs.css"

export default class AboutUs extends React.Component {


    state = {
        title: "",
        body: "",
    };

    constructor(props) {
        super(props);
        this.repository = new SingleContentRepository(FirebasePaths.AboutUs)
    }


    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            title: nextProps.title,
            body: nextProps.body,
        })
    }

    componentDidUpdate(a,b,c){
        if (!(this.props.title) && !(this.props.body)) {
            this.repository
                .getContent()
                .then(doc => {
                    this.setState(doc.data());
                })
        }
    }

    componentDidMount() {
        if (!(this.props.title) && !(this.props.body)) {
            this.repository
                .getContent()
                .then(doc => {
                    this.setState(doc.data());
                })
        }
    }

    render() {
        return (
            <div className="aboutUs">
                <div>
                    <div className="aboutUsTitle">{this.state.title}</div>
                    <div className="aboutUsBodyText">{this.state.body}</div>
                </div>
            </div>);
    }

}
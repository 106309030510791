import * as React from "react";
import "./contact.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MapView from "../MapView/MapView";
import TextField from "@material-ui/core/TextField/TextField";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import MaterialIcon from 'material-icons-react';

export default class Contact extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    state = {
        name: "",
        body: "",
        email: "",
        messageSentResult: "",
        address: "Wollmatinger Straße 43b, Konstanz 78467, Deutschland"
    };

    getContent() {
        return {
            name: this.state.name,
            body: this.state.body,
            email: this.state.email,
        }
    }

    handleSubmit() {

        let allGood;
        let data = this.getContent();

        for (let key in data) {
            allGood = (data[key] && data[key].length > 0) || (data[key].constructor === Array && data[key].size > 0)
            if (!allGood) {
                break;
            }
        }

        if (allGood) {
            axios.post("https://us-central1-international-hair-group-app.cloudfunctions.net/sendEmail", this.getContent())
                .then(response => {
                    this.setState({
                        messageSendResult: "Nachricht erfolgreich versandt. Wir melden uns schnellstmöglich bei dir.",
                        open: true
                    })
                })
                .catch(error => {
                    this.setState({
                        messageSendResult: "Nachricht konnte nicht versandt werden. Versuche es doch später noch einmal.",
                        open: true
                    });
                    console.log(error)
                })
        } else {
            this.setState({
                messageSendResult: "Bitte fülle alle Felder aus!",
                open: true
            });
        }
    }

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        return (

            <div>
                <Header text="Kontaktiere uns"
                        image="https://firebasestorage.googleapis.com/v0/b/international-hair-group-app.appspot.com/o/mood%2Fcontact.jpg?alt=media&token=fc11f883-8f0d-474a-b5d6-0acd3577a9d2"
                        blendMode={true}/>
                <div className="contactContainer">
                    <div className="contactItemContainer">
                        <div className="contactItem">
                            <MaterialIcon size={30} icon="location_on"/>
                            <div className="contactBody">Wollmatinger Straße 43b<br/>
                                Konstanz 78467<br/>
                                Deutschland
                            </div>
                        </div>
                        <div className="contactItem2">
                            <div>
                                <div className="contactItemInner">
                                    <MaterialIcon size={30} icon="phone"/>
                                    <div className="contactBody contactPaddingTop4">
                                        Telefon: <a href="tel:+49 (0) 7531 - 3690726">+49 (0) 7531 - 3690726</a>
                                    </div>
                                </div>
                                <div className="contactItemInner">
                                    <MaterialIcon size={30} icon="email"/>
                                    <div className="contactBody contactPaddingTop4">
                                        Email: <a
                                        href="mailto:info@international-hair-group.de">info@international-hair-group.de</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contactItem3">
                            <div className="contactItemInner">
                                <img className="linkImage" src={require("./instagram.svg")} alt=""/>
                                <div className="contactBody contactPaddingTop4">
                                    <a href="https://www.instagram.com/international_hair_group/">Instagram</a>
                                </div>
                            </div>
                            <div className="contactItemInner">
                                <img className="linkImage" src={require("./facebook.svg")} alt=""/>
                                <div className="contactBody contactPaddingTop4">
                                    <a href="https://www.facebook.com/international.hair.group/">Facebook</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <MapView address={this.state.address}/>
                <MuiThemeProvider theme={this.theme}>
                    <div className="contactContainer">
                        <div className="contactFormTitle">Schreibe uns</div>
                        <div className="contactFormText">Sag uns Hallo oder mach einen Termin.</div>
                        <div className="contactFormContainer">
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className="contactInput"
                                fullWidth
                                label="Name"
                                name="name"
                                type="text"
                                required={true}
                                value={this.state.name}
                                placeholder={"Hier eingeben...."}
                                onChange={this.handleInputChange}
                            />
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className="contactInput"
                                fullWidth
                                label="Email"
                                name="email"
                                type="text"
                                required={true}
                                value={this.state.email}
                                placeholder={"Hier eingeben...."}
                                onChange={this.handleInputChange}
                            />
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className="contactInput"
                                multiline
                                rows={8}
                                fullWidth
                                label="Nachricht"
                                name="message"
                                type="text"
                                required={true}
                                value={this.state.message}
                                placeholder={"Hier eingeben...."}
                                onChange={this.handleInputChange}
                            />
                            <div className="buttonLocator">
                                <Button variant="contained" color="primary" onClick={() => this.handleSubmit()}>
                                    Senden
                                </Button>
                            </div>
                        </div>


                    </div>
                </MuiThemeProvider>
                <Footer/>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">  {this.state.messageSendResult}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}
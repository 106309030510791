import React from "react"
import Button from '@material-ui/core/Button';

import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import "../Util/adminPannel.css"
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import Loading from "../../Util/Loading";
import FirebasePaths from "../../../Repository/FirebasePaths";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import RoutePaths from "../../../Config/RoutePaths";
import {ImageUploadGrid} from "../../Util/ImageUploadGrid";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

export default class EmployeeAdmin extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);

        this.redirectPath = RoutePaths.adminEmployeeList;
        this.id = props.match.params.id;
        this.state = {
            loading: (this.id),
            name: "",
            skill1: "",
            skill2: "",
            skill3: "",
            images: []
        }
        ;

        this.repository = new MultiContentRepository(FirebasePaths.Employee);


        if (this.id) {
            this.repository
                .getContentById(this.id)
                .then(doc => {
                    let data = doc.data();
                    this.setState(data);
                    this.setState({
                        loading: false
                    })
                })
                .catch(err => {
                    window.alert("Fehler beim laden der Daten")
                });
        }

    }

    getContent() {
        return {
            name: this.state.name,
            skill1: this.state.skill1,
            skill2: this.state.skill2,
            skill3: this.state.skill3,
            images: this.state.images
        };
    }

    handleDate(date) {
        this.setState({date: date});
    }

    render() {

        if (this.state.loading) {
            return <Loading/>
        }

        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }


        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>

                            <h1>Angestellter</h1>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    type="text"
                                    value={this.state.name}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Fähigkeit 1"
                                    name="skill1"
                                    value={this.state.skill1}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Fähigkeit 2"
                                    name="skill2"
                                    value={this.state.skill2}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Fähigkeit 3"
                                    name="skill3"
                                    value={this.state.skill3}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <ImageUploadGrid
                                    name="images"
                                    imagesMax={1}
                                    showLoading={() => this.showLoading()}
                                    hideLoading={() => this.hideLoading()}
                                    images={this.state.images}
                                    imageDelete={this.imageDelete}
                                    imageAdded={this.imageAdded}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <Button fullWidth variant="contained" color="primary"
                                        onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>

                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }


}

import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import ListViewComponent from "../../Util/ListViewComponent";
import RoutePaths from "../../../Config/RoutePaths";

export default class ProductListAdmin extends React.Component {

    render() {
        return (<ListViewComponent
            createPath={RoutePaths.adminProductCreate}
            editPath={RoutePaths.adminProductEdit}
            createButtonLabel="Neues Produkt"
            firebasePath={FirebasePaths.Product}/>);
    }
}

import React from "react";
import "./product.css"
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import FirebasePaths from "../../../Repository/FirebasePaths";
import {withRouter} from "react-router-dom";
import {Modal} from "@material-ui/core";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

class Product extends React.Component {

    isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    state = {
        categories: [],
        products: [],
        open: false,
        selectedProduct: {}
    };

    constructor(props) {
        super(props);
        this.repository = new MultiContentRepository(FirebasePaths.Product);
        this.repositoryCategory = new MultiContentRepository(FirebasePaths.ProductCategory);

    }

    componentDidMount() {
        const tempCategories = [];
        const tempProducts = [];
        this.repositoryCategory
            .getContent()
            .then(
                collection => {
                    collection.forEach(category => {
                        tempCategories.push(category.data());
                        this.repository
                            .getContentWhere("category", "==", category.id)
                            .then(products => {
                                    const tempProductList = [];
                                    products.forEach(product => {
                                        tempProductList.push(product.data())
                                    });
                                    tempProducts.push(tempProductList);
                                    this.setState({
                                        categories: tempCategories,
                                        products: tempProducts
                                    })
                                }
                            );
                    });

                }
            );

    }

    open = (product) => {
        this.setState({
            open: true,
            selectedProduct: product
        })
    };

    handleClose = () => {
        this.setState({open: false, selectedProduct: null});
    };
    render() {
        return (
            <div>
                <Header
                    blendMode={true}
                    text="Produkte"
                    image="https://firebasestorage.googleapis.com/v0/b/international-hair-group-app.appspot.com/o/mood%2Fproducts_header_image.jpg?alt=media&token=35578cd9-843f-4fdb-93e2-8be162481aa5"/>

                {
                    this.state.categories && this.state.categories.length > 0 &&
                    this.state.categories.map((category, index) => (
                        this.state.products && this.state.products[index] && this.state.products[index].length > 0 &&
                        <div key={index}>
                            <div className="productOverview">
                                <div className="productOverviewTitle">{category.title}</div>
                                <div className="table">
                                    {this.state.products[index].map((product, index) => (
                                        <div className="item" key={index} onClick={() => this.open(product)}>
                                            <img className="itemImage" src={product.images[0].url} alt=""></img>
                                            <div className="productDescription padding">{product.title}</div>
                                            <div className="productTitle padding">{product.price}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                {
                    !this.isEmpty(this.state.selectedProduct) &&
                    <Modal
                        onClose={this.handleClose}
                        open={this.state.open}>
                        <div className="modalPosition" onClick={() => this.handleClose()}>
                            <div className="modalSize">
                                <div className="modalHeader">Produktdetails</div>
                                <div className="modalContent">
                                    <div className="modalItem imageCenter">
                                        <img className="modalImage"
                                             src={this.state.selectedProduct.images[0].url}
                                             alt=""></img>
                                    </div>
                                    <div className="modalItem">
                                        <div className="productTitle"> {this.state.selectedProduct.title}</div>
                                        <div className="productDescription">{this.state.selectedProduct.body}</div>
                                        <div className="productPrice">{this.state.selectedProduct.price}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>}
                <Footer/>
            </div>);
    }


}

export default withRouter(Product)
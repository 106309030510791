import React from 'react';
import "./app.css"

export default class MobileApps extends React.Component {

    render() {
        return (

            <div className="appOverlay">
                <div className="appContent">

                    <img style={{width: '135px', height: '40px', objectFit: 'contain'}} src={require("./logo.svg")} alt=""/>

                    <br/>

                    <a href='https://play.google.com/store/apps/details?id=com.ihg&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img style={{width: '135px', height: '40px', objectFit: 'contain'}}
                             alt='Jetzt bei Google Play'
                             src={require("./playstore.png")}/>
                    </a>

                    <br/>

                    <a href='https://itunes.apple.com/de/app/international-hair-group/id1441030590?mt=8'>
                        <img style={{width: '135px', height: '40px'}} alt='Jetzt im App Store'
                             src={require("./appstore.svg")}/>
                    </a>

                </div>
            </div>
        );
    }
}
import React from "react"
import Button from '@material-ui/core/Button';
import SingleContentRepository from "../../Repository/SingleContentRepository";
import DefaultInputHandlingComponent from "./DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Loading from "./Loading";
import FirebaseAuthManager from "../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import "../Admin/Util/adminPannel.css"
import RichTextEditor from "react-rte";


export default class SingleContentAdminComponentHTMLBody extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            title: "",
            body: RichTextEditor.createEmptyValue(),
        };

        this.repository = new SingleContentRepository(props.firebasePath);


        this.repository
            .getContent()
            .then(doc => {

                console.log(doc.data());

                this.setState({
                    title: doc.data().title,
                    body: RichTextEditor.createValueFromString(doc.data().body, 'html')
                });
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                console.log("Error getting documents", err);
            });
    }

    handleSubmit() {


        let data = {
            title: this.state.title,
            body: this.state.body.toString('html'),
        };


        let allGood;

        for (let key in data) {
            allGood = (data[key] && data[key].length > 0) || (data[key].constructor === Array && data[key].size > 0)
            if (!allGood) {
                break;
            }
        }

        if (allGood) {
            this.showLoading();
            this.repository
                .saveContent(data)
                .then(() => {
                    this.hideLoading();
                    console.log("stored successful");
                })
                .catch(err => {
                    this.hideLoading();
                    console.log("Error getting documents", err);
                });
        } else {
            window.alert("Bitte alle Felder befüllen")
        }
    }

    onChangeRT = (value) => {
        console.log(value);
        this.setState({body: value});
        // if (this.props.onChange) {
        //     this.props.onChange(
        //         value.toString('html')
        //     );
        // }
    };

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }
        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <h1>{this.props.pageTitle}</h1>
                            <div className="admin-component-text-input">
                                <TextField
                                    fullWidth
                                    label="Überschrift"
                                    name="title"
                                    type="text"
                                    required={true}
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <RichTextEditor
                                value={this.state.body}
                                onChange={this.onChangeRT}
                            />


                            <div className="adminComponentTopMargin">
                                <Button variant="contained" color="primary" onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

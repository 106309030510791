import React from "react";
import "./storeDetail.css"
import MultiContentRepository from "../../../../Repository/MultiContentRepository";
import FirebasePaths from "../../../../Repository/FirebasePaths";
import {withRouter} from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import OpeningHours from "../../OpeningHours/OpeningHours";
import AboutUs from "../../AboutUs/AboutUs";
import MapView from "../../MapView/MapView";
import Employee from "../../Employee/Employee";
import Gallery from "../../Gallery/Gallery";

class StoreDetail extends React.Component {

    state = {
        zoom: 5,
        images: [],
        headerText: "",
        body: "",
        openingHours: {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
        }
    };

    constructor(props) {
        super(props);
        this.repository = new MultiContentRepository(FirebasePaths.Store);
        this.repositoryEmployee = new MultiContentRepository(FirebasePaths.Employee);
        this.id = props.match.params.id;

    }

    componentDidMount() {
        this.repository
            .getContentById(this.id)
            .then(collection => {
                let data = collection.data();
                this.setState({
                    ...data,
                    headerText: <div>Willkommen in {data.area}<br/>-<br/>{data.street}</div>,
                    address: data.street + ", " + data.area + " " + data.areaCode + ", " + data.country,
                    openingHours: {
                        monday: data.monday,
                        tuesday: data.tuesday,
                        wednesday: data.wednesday,
                        thursday: data.thursday,
                        friday: data.friday,
                        saturday: data.saturday,
                        sunday: data.sunday,
                    }
                });

                let employeesList = [];
                Promise
                    .all(data.employees.map(employeeID => {
                        return this.repositoryEmployee.getContentById(employeeID)
                    }))
                    .then(employees => {
                        employees.forEach(employee => {
                            if (employee.data()) {
                                employeesList.push(employee.data())
                            }
                        });
                        this.setState({employeesList: employeesList})
                    });


            });


    }

    render() {
        return (
            <div>
                <Header blendMode={true}
                        text={this.state.headerText}
                        image={this.state.images[0] ? this.state.images[0].url : ""}/>
                <OpeningHours
                    openingHours={this.state.openingHours}
                />
                <Employee employees={this.state.employeesList}/>
                <AboutUs title="Beschreibung" body={this.state.body}/>
                <MapView displayTitleAndAddress={true} address={this.state.address}/>
                <Gallery
                    hideFilter={true}
                    galleries={[{images: this.state.images}]}
                />
                <Footer/>
            </div>);
    }


}

export default withRouter(StoreDetail)
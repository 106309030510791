export default class FirebasePaths {
    static AboutUs = "aboutUs";
    static Employee = "employee";
    static Haircut = "haircut";
    static Imprint = "imprint";
    static News = "news";
    static PrivacyNotice = "privacyNotice";
    static Product = "product";
    static ProductCategory = "productCategory";
    static Store = "store";
    static Gallery = "gallery";
    static Images = "/Images/";
    static HotNews = "hotNews";
    static Admin = "admin";
    static PriceList = "priceList";
    static Config = "config";
}

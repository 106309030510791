import React, {Component} from "react";
import {Route, withRouter} from "react-router-dom";

import "./app.css"
import Home from "./Component/Public/Home/Home";
import Header from "./Component/Public/Header/Header";
import AboutUsAdmin from "./Component/Admin/AboutUsAdmin/AboutUsAdmin";
import Login from "./Component/Public/Login/Login";
import ImprintAdmin from "./Component/Admin/Imprint/ImprintAdmin";
import PrivacyNoticeAdmin from './Component/Admin/PrivacyNoticeAdmin/PrivacyNoticeAdmin';
import NewsListAdmin from './Component/Admin/News/NewsListAdmin';
import NewsAdmin from "./Component/Admin/News/NewsAdmin";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import GalleryAdmin from "./Component/Admin/Gallery/GalleryAdmin";
import RoutePaths from "./Config/RoutePaths";
import GalleryListAdmin from "./Component/Admin/Gallery/GalleryListAdmin";
import EmployeeListAdmin from "./Component/Admin/Employee/EmployeeListAdmin";
import EmployeeAdmin from "./Component/Admin/Employee/EmployeeAdmin";
import ProductListAdmin from "./Component/Admin/Product/ProductListAdmin";
import ProductAdmin from "./Component/Admin/Product/ProductAdmin";
import ProductCategoryListAdmin from "./Component/Admin/Product/ProductCategoryListAdmin";
import ProductCategoryAdmin from "./Component/Admin/Product/ProductCategoryAdmin";
import StoreListAdmin from "./Component/Admin/Store/StoreListAdmin";
import StoreAdmin from "./Component/Admin/Store/StoreAdmin";
import moment from "moment";
import de from "moment/locale/de";
import MomentUtils from '@date-io/moment';
import HotNewsAdmin from "./Component/Admin/News/HotNewsAdmin";
import HotNewsListAdmin from "./Component/Admin/News/HotNewsListAdmin";
import PriceListAdmin from "./Component/Admin/PriceListAdmin/PriceListAdmin";
import Navigation from "./Component/Public/Navigation/Navigation";
import Product from "./Component/Public/Product/Product";
import StoreDetail from "./Component/Public/Store/Detail/StoreDetail";
import Imprint from "./Component/Public/Imprint/Imprint";
import PrivacyNotice from "./Component/Public/PrivacyNotice/PrivacyNotice";
import Contact from "./Component/Public/Contact/Contact";
import NewsOverview from "./Component/Public/News/NewsOverview/NewsOverview";
import NewsDetail from "./Component/Public/News/NewsDetail/NewsDetail";
import Modal from "@material-ui/core/Modal/Modal";
import MobileApps from "./Component/Public/MobileApps/MobileApps"
import PushAdminComponent from "./Component/Admin/Push/PushAdmin";

import {BrowserView, MobileView} from "react-device-detect";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openCookieBanner: !localStorage.getItem("zaferAcceptedCookie") && this.props.location.pathname !== RoutePaths.privacyNotice,
        }
    }

    render() {
        return (
            <div>
                {/**
                 *  route to different component
                 */}
                <BrowserView>


                    <div>
                        <Navigation/>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={de} moment={moment}>
                            <Route exact={true} path={RoutePaths.home} component={Home}/>
                            <Route exact={true} path={RoutePaths.about} component={Header}/>
                            <Route exact={true} path={RoutePaths.login} component={Login}/>
                            <Route exact={true} path={RoutePaths.adminAbout} component={AboutUsAdmin}/>
                            <Route exact={true} path={RoutePaths.adminImprint} component={ImprintAdmin}/>
                            <Route exact={true} path={RoutePaths.adminPrivacyNotice} component={PrivacyNoticeAdmin}/>
                            <Route exact={true} path={RoutePaths.adminNewsList} component={NewsListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminNewsEdit} component={NewsAdmin}/>
                            <Route exact={true} path={RoutePaths.adminNewsCreate} component={NewsAdmin}/>
                            <Route exact={true} path={RoutePaths.adminHotNewsList} component={HotNewsListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminHotNewsEdit} component={HotNewsAdmin}/>
                            <Route exact={true} path={RoutePaths.adminHotNewsCreate} component={HotNewsAdmin}/>
                            <Route exact={true} path={RoutePaths.adminGalleryList} component={GalleryListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminGalleryEdit} component={GalleryAdmin}/>
                            <Route exact={true} path={RoutePaths.adminGalleryCreate} component={GalleryAdmin}/>
                            <Route exact={true} path={RoutePaths.adminEmployeeList} component={EmployeeListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminEmployeeEdit} component={EmployeeAdmin}/>
                            <Route exact={true} path={RoutePaths.adminEmployeeCreate} component={EmployeeAdmin}/>
                            <Route exact={true} path={RoutePaths.adminProductList} component={ProductListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminProductEdit} component={ProductAdmin}/>
                            <Route exact={true} path={RoutePaths.adminProductCreate} component={ProductAdmin}/>
                            <Route exact={true} path={RoutePaths.adminPriceListCreate} component={PriceListAdmin}/>
                            <Route exact={true} path={RoutePaths.products} component={Product}/>
                            <Route exact={true} path={RoutePaths.storeDetail} component={StoreDetail}/>
                            <Route exact={true} path={RoutePaths.imprint} component={Imprint}/>
                            <Route exact={true} path={RoutePaths.privacyNotice} component={PrivacyNotice}/>
                            <Route exact={true} path={RoutePaths.contact} component={Contact}/>
                            <Route exact={true} path={RoutePaths.news} component={NewsOverview}/>
                            <Route exact={true} path={RoutePaths.newsDetail} component={NewsDetail}/>
                            <Route exact={true} path={RoutePaths.adminProductCategoryList}
                                   component={ProductCategoryListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminProductCategoryEdit}
                                   component={ProductCategoryAdmin}/>
                            <Route exact={true} path={RoutePaths.adminProductCategoryCreate}
                                   component={ProductCategoryAdmin}/>
                            <Route exact={true} path={RoutePaths.adminStoreList}
                                   component={StoreListAdmin}/>
                            <Route exact={true} path={RoutePaths.adminStoreCreate}
                                   component={StoreAdmin}/>
                            <Route exact={true} path={RoutePaths.adminStoreEdit}
                                   component={StoreAdmin}/>
                            <Route exact={true} path={RoutePaths.adminPush}
                                   component={PushAdminComponent}/>
                        </MuiPickersUtilsProvider>
                    </div>

                    <Modal
                        disableBackdropClick={true}
                        open={this.state.openCookieBanner}>
                        <div className="modalCookiePosition">

                            <div className="modalCookieBackground">
                                Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können,
                                verwenden wir Cookies. Dabei werden Daten, die Sie beim Besuch unserer Website
                                hinterlassen
                                gespeichert und unter Umstän0den an die in unserer Datenschutzerklärung erwähnten
                                Dritten
                                weitergegeben.
                                Entscheiden Sie sich gegen die Verwendung von Cookies, kann dadurch die Funktionalität
                                der
                                Website eingeschränkt sein.

                                <div className="toStoreButton" onClick={() => this.acceptCookie()}>Ich stimme der
                                    Nutzung
                                    von Cookies ausdrücklich zu</div>
                                <div className="toStoreButton" onClick={() => this.openPrivacy()}>Weitere Informationen
                                </div>
                                <div className="toStoreButton" onClick={() => this.deleteCookies()}>Cookies löschen und
                                    Ablehnen
                                </div>
                            </div>

                        </div>
                    </Modal>
                </BrowserView>

                <MobileView>
                    <MobileApps/>
                </MobileView>
            </div>
        );
    }

    acceptCookie() {
        localStorage.setItem("zaferAcceptedCookie", "true");
        this.setState({
            openCookieBanner: false
        })

    }

    openPrivacy() {
        this.props.history.push(RoutePaths.privacyNotice)
    }

    deleteCookies() {
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        window.location = "https://tools.google.com/dlpage/gaoptout"
    }

    componentDidMount() {
        document.title = 'International Hair Group'
    }

    componentWillMount() {
        document.title = 'International Hair Group';
        this.unlisten = this.props.history.listen((location, action) => {
            console.log(location);
            this.setState({
                openCookieBanner: !localStorage.getItem("zaferAcceptedCookie") && location.pathname !== RoutePaths.privacyNotice,
            })
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

}

export default withRouter(App);

import CircularProgress from '@material-ui/core/CircularProgress';
import React from "react";
import Grid from "@material-ui/core/Grid/Grid";

export default class Loading extends React.Component {
    render() {
        return (
            <Grid container direction="column"
                  alignItems="center"
                  justify="center"
                  style={{height: '100vh', minHeight: '100vh', position: 'absolute'}}>
                <CircularProgress size={100}/>
            </Grid>
        )
    }
}
import FirebaseConfig from "../Config/FirebaseConfig";
export default class SingleContentRepository {
  constructor(fireStorePath) {
    this.fireStorePath = fireStorePath;
  }

  getContent() {
    return FirebaseConfig.firestore()
      .collection(this.fireStorePath)
      .doc("content")
      .get();
  }

  saveContent(content) {
    return FirebaseConfig.firestore()
      .collection(this.fireStorePath)
      .doc("content")
      .set(content);
  }
}

import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import SingleContentAdminComponentHTMLBody from "../../Util/SingleContentAdminComponentHTMLBody";

export default class PrivacyNoticeAdmin extends DefaultInputHandlingComponent {
    render() {
        return (
            <SingleContentAdminComponentHTMLBody
                pageTitle="Datenschutzerklärung"
                firebasePath={FirebasePaths.PrivacyNotice}
            />
        );
    }
}

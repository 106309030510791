import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import RoutePaths from "../../../Config/RoutePaths";
import ListViewComponent from "../../Util/ListViewComponent";

export default class EmployeeListAdmin extends React.Component {

    render() {
        return (<ListViewComponent
            createPath={RoutePaths.adminEmployeeCreate}
            editPath={RoutePaths.adminEmployeeEdit}
            createButtonLabel="Neuen Angestellten "
            firebasePath={FirebasePaths.Employee}/>);
    }
}

import * as React from "react";
import "./Footer.css"
import {withRouter} from "react-router-dom";
import RoutePaths from "../../../Config/RoutePaths";

class Footer extends React.Component {

    navigateTo = (id) => {
        if (id === "imprint") {
            this.props.history.push(RoutePaths.imprint);
        } else {
            this.props.history.push(RoutePaths.privacyNotice);
        }
    };

    render() {
        return (
            <div>
                <div className="footer">
                    <img className="footerLogo" src={require("./logo.png")} alt=""/>
                    <div className="footerTable">
                        <div className="footerItem">
                            <div className="title">Firmenname</div>
                            <div className="text">International <br/> Group</div>
                        </div>

                        <div className="footerItem">
                            <div className="title">Addresse</div>
                            <div className="text">
                                Wollmatinger Straße 43b<br/>
                                Konstanz 78467<br/>
                                Deutschland
                            </div>
                        </div>

                        <div className="footerItem">
                            <div className="title">Mobil</div>
                            <div className="text">+49 (0) 1514 0013095</div>
                        </div>

                        <div className="footerItem">
                            <div className="title">Geschäftsführer</div>
                            <div className="text">Zafer Capar</div>
                        </div>
                        <div className="footerItem">
                            <div className="title">Öffnungszeiten</div>
                            <div className="text">
                                Montag bis Freitag <br/>
                                09:00 Uhr - 18:30 Uhr <br/>
                                Samstag <br/>
                                09:00 Uhr - 15:30 Uhr <br/>
                                Sonntags geschlossen
                            </div>
                        </div>

                        <div className="footerItem">
                            <div className="title">Telefon</div>
                            <div className="text"> +49 (0) 7531 - 3690726</div>
                        </div>
                    </div>

                </div>
                <div className="footerLinkBar">
                    <img className="footerLogo" src={require("./empty.png")} alt=""/>
                    <div className="footerTable">
                        <div className="footerInfo">
                            © International Hair Group GmbH - 2018
                        </div>
                        <div onClick={() => this.navigateTo("privacyNotice")} className="footerLink">
                            <pre className="footerLink">Datenschutz</pre>
                            erklärung
                        </div>
                        <div onClick={() => this.navigateTo("imprint")} className="footerLink">
                            Impressum
                        </div>
                    </div>
                </div>
            </div>);
    }

}

export default withRouter(Footer)
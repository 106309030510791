import React from "react"

import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import FirebasePaths from "../../../Repository/FirebasePaths";
import BaseNewsAdmin from "./BaseNewsAdmin";
import RoutePaths from "../../../Config/RoutePaths";

export default class NewsAdmin extends React.Component {

    render() {
        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }
        return (
            <BaseNewsAdmin
                redirectPath={RoutePaths.adminNewsList}
                title="News"
                firebasePath={FirebasePaths.News}
            />
        );
    }
}
import React from "react"
import Button from '@material-ui/core/Button';
import DefaultInputHandlingComponent from "../../Util/DefaultInputHandlingComponent";
import TextField from "@material-ui/core/TextField/TextField";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import Loading from "../../Util/Loading";
import "../Util/adminPannel.css"
import FirebasePaths from "../../../Repository/FirebasePaths";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import RoutePaths from "../../../Config/RoutePaths";
import FirebaseAuthManager from "../../../Config/FirebaseAuthManager";
import Redirect from "react-router-dom/es/Redirect";
import {ImageUploadGrid} from "../../Util/ImageUploadGrid";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";

export default class StoreAdmin extends DefaultInputHandlingComponent {

    theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FFD400'
                },
                secondary: {
                    main: '#58595B'
                }
            }
        },
    );

    constructor(props) {
        super(props);


        this.redirectPath = RoutePaths.adminStoreList;
        this.id = props.match.params.id;
        this.state = {
            loading: (this.id),
            employeesList: [],
            street: "",
            areaCode: "",
            area: "",
            country: "",
            title: "",
            body: "",
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "geschlossen",
            employees: [],
            images: [],
        };

        this.repository = new MultiContentRepository(FirebasePaths.Store);
        this.employeeRepository = new MultiContentRepository(FirebasePaths.Employee);
        this.employeeRepository.getContent("").then(result => {
            const tempItems = [];
            result.forEach(element => {
                tempItems.push({
                    id: element.id,
                    data: element.data()
                });
            });
            this.setState({
                employeesList: tempItems,
                loading: false
            });
        });
        if (this.id) {
            this.repository
                .getContentById(this.id)
                .then(doc => {
                    this.setState(doc.data());
                    this.setState({loading: false});
                })
                .catch(err => {
                    window.alert("Fehler beim laden der Daten")
                });
        }

    }

    getContent() {
        return {
            street: this.state.street,
            areaCode: this.state.areaCode,
            area: this.state.area,
            country: this.state.country,
            title: this.state.title,
            body: this.state.body,
            monday: this.state.monday,
            tuesday: this.state.tuesday,
            wednesday: this.state.wednesday,
            thursday: this.state.thursday,
            friday: this.state.friday,
            saturday: this.state.saturday,
            sunday: this.state.sunday,
            employees: this.state.employees,
            images: this.state.images,
        }
    }

    render() {

        if (this.state.loading) {
            return <Loading/>
        }

        if (FirebaseAuthManager.isNotAuthenticated()) {
            return <Redirect to='/login'/>
        }


        return (
            <MuiThemeProvider theme={this.theme}>
                <Grid container justify="center" className="Padding-content, adminComponentBody">
                    <Grid item xs={8}>
                        <Paper className="Padding-content" id="admin-component-normal-padding" elevation={4}>
                            <h1>Geschäft</h1>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    fullWidth
                                    label="Name"
                                    name="title"
                                    type="text"
                                    value={this.state.title}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    fullWidth
                                    label="Beschreibung"
                                    name="body"
                                    multiline={true}
                                    rows={15}
                                    value={this.state.body}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <InputLabel className="inputLabel">
                                    Adresse
                                </InputLabel>
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Strasse und Hausnummer"
                                    name="street"
                                    type="text"
                                    value={this.state.street}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="PLZ"
                                    name="areaCode"
                                    type="text"
                                    value={this.state.areaCode}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Stadt"
                                    name="area"
                                    type="text"
                                    value={this.state.area}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}/>
                            </div>

                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Land"
                                    name="country"
                                    type="text"
                                    value={this.state.country}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="admin-component-text-input">
                                <InputLabel>Mitarbeiter</InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    id="employees"
                                    name="employees"
                                    value={this.state.employees}
                                    onChange={this.handleInputChange}
                                >
                                    {
                                        this.state.employeesList.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.data.name}
                                            </MenuItem>))
                                    }
                                </Select>
                            </div>
                            <div className="adminComponentTopMargin">
                                <InputLabel className="inputLabel">
                                    Öffnungszeiten
                                </InputLabel>
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Montag"
                                    name="monday"
                                    type="text"
                                    value={this.state.monday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Dienstag"
                                    name="tuesday"
                                    type="text"
                                    value={this.state.tuesday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Mittwoch"
                                    name="wednesday"
                                    type="text"
                                    value={this.state.wednesday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Donnerstag"
                                    name="thursday"
                                    type="text"
                                    value={this.state.thursday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Freitag"
                                    name="friday"
                                    type="text"
                                    value={this.state.friday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Samstag"
                                    name="saturday"
                                    type="text"
                                    value={this.state.saturday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <TextField
                                    className="textField"
                                    label="Sonntag"
                                    name="sunday"
                                    type="text"
                                    value={this.state.sunday}
                                    placeholder={"Hier eingeben...."}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <ImageUploadGrid
                                    imagesMax={9}
                                    showLoading={() => this.showLoading()}
                                    hideLoading={() => this.hideLoading()}
                                    images={this.state.images}
                                    imageDelete={this.imageDelete}
                                    imageAdded={this.imageAdded}
                                />
                            </div>
                            <div className="adminComponentTopMargin">
                                <Button fullWidth variant="contained" color="primary"
                                        onClick={() => this.handleSubmit(true)}>
                                    Speichern
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

/* eslint-disable no-undef */
import * as React from "react";
import "./MapView.css"
import axios from "axios";

export default class MapView extends React.Component {

    componentDidMount() {
        if (this.props.address) {
            this.displayMap(this.props.address);
        }
    }

    componentWillReceiveProps(nextProps, nextContent) {

        if (nextProps.address) {
            this.displayMap(nextProps.address);

        }


    }

    displayMap(address) {
        let locationUrl =
            "https://nominatim.openstreetmap.org/search?format=json&limit=1&q="
            + address;
        axios.get(locationUrl)
            .then(response => {
                if (response && response.data && response.data.length === 1 && response.data[0].lat && response.data[0].lon) {
                    let map = L.map('map').setView([response.data[0].lat, response.data[0].lon], 14);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                        id: 'mapbox.streets',
                        minZoom: 10,
                        maxZoom: 18,
                        accessToken: 'pk.eyJ1IjoiaWhnLXphZmVyLWRldiIsImEiOiJjam5laWcweGUwNGlpM2ttZ242dHR4aWpwIn0.5jaxGCvGDoxKgewn5Ppwuw'
                    }).addTo(map);
                    map.dragging.disable();
                    map.scrollWheelZoom.disable();
                    L.marker([response.data[0].lat, response.data[0].lon]).addTo(map);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div>
                {this.props.displayTitleAndAddress &&
                <div>
                    <div className="mapTitle">Standort</div>
                    <div className="mapAddress">{this.props.address}</div>
                </div>}

                <div id="map" className="map"></div>
            </div>
        );
    }

}
import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ImageCompressor from 'image-compressor.js';
import {FirebaseStorageRepository} from "../../Repository/FirebaseStorageRepository";
import FirebasePaths from "../../Repository/FirebasePaths";

export class ImageUploadGrid extends React.Component {

    constructor(props) {
        super(props);
        this.firebaseStorageRepository = new FirebaseStorageRepository(FirebasePaths.Images)
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const _this = this;
        this.props.showLoading();
        new ImageCompressor(file, {
            quality: .7,
            success(result) {
                _this.firebaseStorageRepository
                    .store(result, ".jpeg")
                    .then(p => {
                        p.ref.getDownloadURL()
                            .then(url => {
                                _this.props.imageAdded({url: url, path: p.ref.location.path});
                                _this.props.hideLoading();
                            });
                    });
            },
            error(e) {
                _this.props.hideLoading();
            }
        })
    };

    itemDelete(item) {
        const _this = this;
        this.props.showLoading();
        this.firebaseStorageRepository
            .delete(item.path)
            .then(() => {
                _this.props.imageDelete(item);
                _this.props.hideLoading();
            })
    }

    render() {
        return (
            <div>
                {(this.props.images && this.props.images.length < this.props.imagesMax) && (
                    <input accept="image/*" type="file" onChange={this.fileChangedHandler}/>)}
                {(this.props.images && this.props.images.length > 0) && (
                    <Grid justify="center" container spacing={16} className="Padding-content">
                        {this.props.images.map((item, index) => (
                            <Grid key={index} item xs={4}>
                                <Card className="Padding-content">
                                    <img width={128} height={128} src={item.url} alt=""/>
                                    <IconButton
                                        aria-label="Delete" onClick={() => this.itemDelete(item)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>)}
            </div>);
    }

}
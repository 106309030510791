import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import ListViewComponent from "../../Util/ListViewComponent";
import RoutePaths from "../../../Config/RoutePaths";

export default class HotNewsListAdmin extends React.Component {

    render() {
        return (
            <ListViewComponent
                createPath={RoutePaths.adminHotNewsCreate}
                editPath={RoutePaths.adminHotNewsEdit}
                createButtonLabel="Neue Aktion"
                firebasePath={FirebasePaths.HotNews}/>);
    }
}

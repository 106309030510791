import FirebaseConfig from "./FirebaseConfig";

export default class FirebaseAuthManager {

    static isAuthenticated() {
        return FirebaseConfig.auth().currentUser;
    }

    static isNotAuthenticated() {
        return !FirebaseConfig.auth().currentUser;
    }

    static signIn(username, password) {
        return FirebaseConfig.auth()
            .signInWithEmailAndPassword(username, password)
    }

    static signOut() {
        return FirebaseConfig.auth()
            .signOut()
    }

    static auth() {
        return FirebaseConfig.auth();
    }
}

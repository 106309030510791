import React from "react";
import "./employee.css"

export default class Employee extends React.Component {

    state = {
        employees: [],
    };


    componentWillReceiveProps(nextProps, nextContent) {


        console.log(nextProps);

        if (nextProps && nextProps.employees && nextProps.employees.length > 0) {
            this.setState({employees: nextProps.employees})
        }

    }


    render() {
        return (
            <div className="employee">
                <div className="employeeTitle">Angestellte</div>
                {this.state.employees && this.state.employees.length > 0 &&
                <div>
                    <div className="employeeItemContainer">
                        {this.state.employees.map((item, index) => (
                            <div key={index}><img key={index}
                                      className="employeeItem"
                                      src={item.images[0].url} alt=""/>
                                <div className="employeeName">{item.name}</div>
                                <div className="employeeSkill">{item.skill1}</div>
                                <div className="employeeSkill">{item.skill2}</div>
                                <div className="employeeSkill">{item.skill3}</div>
                            </div>
                        ))}
                    </div>

                </div>}
            </div>);
    }

}
import React from "react";
import FirebasePaths from "../../../Repository/FirebasePaths";
import MultiContentRepository from "../../../Repository/MultiContentRepository";
import "./gallery.css"
import {Modal} from "@material-ui/core";

export default class Gallery extends React.Component {

    state = {
        galleries: [],
        selectedGallery: 0,
        modalImageUrl: "",
        open: false
    };

    constructor(props) {
        super(props);
        this.hideFilter = props.hideFilter;
        this.repository = new MultiContentRepository(FirebasePaths.Gallery);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.galleries) {
            this.setState({
                galleries: nextProps.galleries
            })
        }
    }

    componentDidMount() {
        if (this.props.galleries) {
            this.setState({
                galleries: this.props.galleries
            })
        } else {
            this.repository
                .getContent()
                .then(collection => {
                    const tempItems = [];
                    collection.forEach(element => {
                        tempItems.push(element.data())
                    });
                    this.setState({
                        galleries: tempItems
                    });
                })
        }

    }

    open = (url) => {
        this.setState({
            open: true,
            modalImageUrl: url
        })
    };

    handleClose = () => {
        this.setState({open: false, selectedProduct: null});
    };

    render() {
        return (
            <div>
                <div className="gallery">
                    {!this.hideFilter && <div className="galleryTitle">Gallery</div>}
                    {this.state.galleries && this.state.galleries.length > 0 &&
                    <div>
                        {!this.hideFilter &&
                        <div className="galleryFilterContainer">
                            {this.state.galleries.map((item, index) => (
                                <div key={index}
                                     className={this.state.selectedGallery === index ? "galleryFilter active" : "galleryFilter"}
                                     onClick={() => this.changeFilter(index)}>{item.title}</div>
                            ))}
                        </div>
                        }
                        <div className="galleryItemContainer">
                            {this.state.galleries[this.state.selectedGallery].images.map((item, index) => (
                                <img onClick={() => this.open(item.url)} key={index}
                                     className="galleryItem"
                                     src={item.url} alt=""/>
                            ))}
                        </div>
                    </div>}
                </div>
                <Modal
                    onClose={this.handleClose}
                    open={this.state.open}>
                    <div className="modalPositionGallery" onClick={() => this.handleClose()}>
                        <div>
                            <img className="modalImageGallery"
                                 src={this.state.modalImageUrl}
                                 alt=""></img>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }


    changeFilter = (index) => {
        this.setState({
            selectedGallery: index
        })
    }
}